<template>
  <BasicModal
    :title="title"
    width="500px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <BasicForm
      ref="formRef"
      :labelCol="{ span: 6 }"
      :formItemsMap="formItemsMap"
      v-model:formData="formData"
    ></BasicForm>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  computed,
  ref,
  defineEmits,
  reactive,
  onMounted,
  watch,
} from "vue";
import { apiDictListByCode } from "@/api/common";
import { message } from "ant-design-vue";
import { apiMenu } from "@/api/authority";

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

const title = computed(() => `${props.detail ? "修改" : "添加"}菜单`);

onMounted(() => {
  initFormItems();
  initFormData();
});

const formItemsMap = reactive({
  moduleName: {
    label: "应用模块名称",
    prop: "moduleName",
    type: "input",
    disabled: true,
  },
  permission: {
    label: "菜单标识",
    prop: "permission",
    type: "input",
    disabled: true,
  },
  menuIcon: {
    label: "菜单图标",
    prop: "menuIcon",
    type: "input",
    disabled: true,
  },
  menuName: {
    label: "菜单名称",
    prop: "menuName",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  menuPath: {
    label: "菜单url地址",
    prop: "menuPath",
    type: "input",
    required: true,
    requiredMessage: "请输入",
    disabled: false,
  },
  menuType: {
    label: "菜单类型",
    prop: "menuType",
    type: "select",
    options: [],
    disabled: true,
  },
  openWith: {
    label: "打开方式",
    prop: "openWith",
    type: "select",
    options: [],
    disabled: true,
  },
  menuRemark: {
    label: "备注",
    prop: "menuRemark",
    type: "textarea",
  },
});
const initFormItems = () => {
  apiDictListByCode("MENU_TYPE").then((res) => {
    formItemsMap.menuType.options = res.result;
  });
  apiDictListByCode("OPEN_TYPE").then((res) => {
    formItemsMap.openWith.options = res.result;
  });
};
const formData = reactive({
  moduleName: "",
  permission: "",
  menuIcon: "",
  menuName: "",
  menuPath: "",
  menuType: undefined,
  openWith: undefined,
  menuRemark: "",
});
const initFormData = async () => {
  if (!props.detail) return;
  console.log(props.detail);
  for (const key in formData) {
    formData[key] = props.detail[key];
  }
};
watch(
  () => formData.openWith,
  (newData) => {
    formItemsMap.menuPath.disabled = newData === "0";
  }
);

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    const { detail } = props;
    const params = {
      ...formData,
    };
    if (detail) {
      params.id = detail.menuId;
    }
    confirmLoading.value = true;
    await apiMenu[detail ? "edit" : "add"](params);
    message.success(`${detail ? "修改" : "添加"}成功`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>
