<template>
  <div class="menu">
    <div class="menu-left">
      <ModuleList
        v-model:selectValue="selectModule"
        :list="moduleList"
        :loading="moduleLoading"
        @change="changeModule"
      />
    </div>
    <div class="menu-right">
      <div class="menu-right-header">
        <div class="menu-right-title">
          <span class="menu-right-title-icon"></span>
          菜单列表
        </div>
      </div>
      <div class="menu-right-body">
        <BasicTable ref="tableRef" v-bind="tableConfig">
          <template #slotMenuName="{ row }">
            <component :is="$antIcons[row.menuIcon]" />
            <span style="margin-left: 8px">{{ row.menuName }}</span>
          </template>
          <template #slotMenuType="{ row }">
            {{ menuTypeMap[row.menuType] }}
          </template>
          <template #slotOpenWith="{ row }">
            {{ openWithMap[row.openWith] }}
          </template>
        </BasicTable>
      </div>
    </div>
  </div>
  <MenuEditModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :detail="currentRow"
    @success="editSuccess"
  ></MenuEditModal>
</template>
<script setup>
// defineOptions({
//   name: "ApplicationMenu",
// });
import { ref, reactive, onMounted } from "vue";
import { BasicTable } from "@/components/basic/table";
import { apiMenu } from "@/api/authority";
import MenuEditModal from "./components/MenuEditModal";
import { apiDictListByCode } from "@/api/common";

onMounted(() => {
  initOptions();
  initModuleList();
});

const menuTypeMap = ref({});
const openWithMap = ref({});
const initOptions = async () => {
  apiDictListByCode("MENU_TYPE").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    menuTypeMap.value = _map;
  });
  apiDictListByCode("OPEN_TYPE").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    openWithMap.value = _map;
  });
};

const selectModule = ref({});
const moduleLoading = ref(false);
const moduleList = ref([]);
const initModuleList = async () => {
  moduleLoading.value = true;
  try {
    const { result } = await apiMenu.moduleList();
    moduleList.value = result;
    if (result.length > 0) {
      selectModule.value = result[0];
      initTableData();
    }
  } catch (error) {
    console.log(error);
  }
  moduleLoading.value = false;
};

const changeModule = () => {
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  rowKey: "permission",
  showIndex: false,
  showSelect: false,
  pagination: false,
  remote: false,
  loading: false,
  defaultExpandAllRows: true,
  isFinish: true,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 230px)",
  },
  columns: [
    {
      title: "菜单名称",
      type: "customize",
      slotName: "slotMenuName",
      width: 180,
      align:"left"
    },
    {
      title: "菜单类型",
      type: "customize",
      slotName: "slotMenuType",
      width: 80,
      align:"left"
    },
    {
      title: "菜单地址",
      type: "text",
      key: "menuPath",
      width: 320,
      align:"left"
    },
    {
      title: "打开方式",
      type: "customize",
      slotName: "slotOpenWith",
      width: 80,
      align:"left"
    },
    {
      title: "备注",
      type: "text",
      key: "menuRemark",
      width: 300,
      align:"left"
    },
  ],
  action: {
    title: "操作",
    width: 70,
    fixed: "right",
    align:"left",
    items: [
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        onClick: (row) => {
          showModal(row);
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { moduleId } = selectModule.value;
  tableConfig.loading = true;
  tableConfig.isFinish = false;
  try {
    const { result } = await apiMenu.menuList(moduleId);
    result&&result.forEach(item=>{
      if(item.children&&item.children.length == 0){
        item.children = null;
      }
      item.children&&item.children.forEach(_item=>{
        if(_item.children&&_item.children.length == 0){
          _item.children = null;
        }
      })
    })
    tableRef.value.dataSourceRef = result;
    tableConfig.isFinish = true;
  } catch (error) {
    tableConfig.isFinish = true;
    console.log(error);
  }
  tableConfig.loading = false;
};

const modalVisible = ref(false);
const currentRow = ref(null);
const showModal = (row) => {
  const { moduleName } = selectModule.value;
  currentRow.value = { ...row, moduleName };
  modalVisible.value = true;
};
const editSuccess = () => {
  initTableData();
};
</script>
<style lang="less" scoped>
.menu {
  display: flex;
  gap: 8px;
  height: 100%;
  &-left {
    width: 240px;
    height: 100%;
    background: #fff;
  }
  &-right {
    flex: 1;
    height: 100%;
    background: #fff;
    overflow-y: auto;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      padding: 0 20px;
    }
    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      &-icon {
        width: 3px;
        height: 16px;
        background: #0256ff;
      }
    }
    &-body {
      padding: 0 20px 20px;
    }
  }
}
</style>
